<template>
  <div>
    <span
      v-if="item.state == 0 && item.gotoUrl == null&&item.msgObjectCode !== 'TX-WHNX01-3'"
      class="iv-main-hover-color iv-operate-item iv-pull-left uit-editor-list iv-fs-14"
      :class="{'iv-pointer': !isRead || isOperate, 'iv-main-color': true}"
      @click="operateClick(item, '设为已读')"
    >{{'设为已读'}}</span>
    <span
      v-if="item.state == 0 && item.gotoUrl !== null&&item.msgObjectCode !== 'TX-WHNX01-3'"
      class="iv-main-hover-color iv-operate-item iv-pull-left uit-editor-list iv-fs-14"
      :class="{'iv-pointer': !isRead || isOperate, 'iv-main-color': true}"
      @click="operateClick(item, '处理')"
    >{{'处理'}}</span>
    <span
        v-if="item.state == 0 &&item.msgObjectCode == 'TX-WHNX01-3' && item.gotoUrl !== null"
        class="iv-main-hover-color iv-operate-item iv-pull-left uit-editor-list iv-fs-14"
        :class="{'iv-pointer': !isRead || isOperate, 'iv-main-color': true}"
        @click="operateClick(item, '查看详情')"
    >{{'查看详情'}}</span>
    <span
      v-if="item.state == 1 && item.msgObjectCode != 'TX-WHNX01-3'"
      class="iv-operate-item iv-pull-left uit-editor-list iv-fs-14"
      :class="{'iv-pointer': !isRead || isOperate, 'iv-main-color': false}"
    >{{'已读'}}</span>
    <span
        v-if="item.state == 1 && item.msgObjectCode == 'TX-WHNX01-3'"
        class="iv-operate-item iv-pull-left uit-editor-list iv-fs-14"
        :class="{'iv-pointer': !isRead || isOperate, 'iv-main-color': false}"
        @click="operateClick(item, '查看详情')"
    >{{'已读'}}</span>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  watch: {
    item: {
      handler (newVal) {
        this.setOperaName(newVal)
      },
      deep: true
    }
  },
  inject: {
    operateClick: {
      default () {
        return () => {}
      }
    }
  },
  data () {
    return {
      isOperate: false,
      isRead: true,
      textName: ''
    }
  },
  created () {
    this.setOperaName()
  },
  methods: {
    setOperaName (objValue) {
      let obj = objValue || this.item
      this.isRead = !!obj.state
      switch (obj.actionType) {
        case 2:
        case 3:
          this.textName = obj.buttonName || '操作'
          this.isOperate = true
          break
        default:
          this.textName = this.isRead ? '已读' : '知道了'
          break
      }
    }
  }
}
</script>

