<template>
  <div class="iv-message">
    <div class="iv-main-width iv-p-t-20" >
      <Tab ref="tab" :navList="navList" @on-click="tabClick" v-if="show"></Tab>
      <component @forceRender="forceRenderFn" ref="learnContent" :is="learnContent" :name="learnContent" @onloaded="onloaded"></component>
    </div>
  </div>
</template>

<script>
import Tab from '@/components/tab'
import allMessage from './all-message'
import unreadMessage from './unread-message'
import { getDateForDay } from '@/utils'

export default {
  data () {
    return {
      show: false,
      count: 0,
      hisCount: 0,
      learnContent: 'unreadMessage',
    }
  },
  async created () {
    this.getNnreadList()
    this.getHistoryNnreadList()
  },
  methods: {
    onloaded(){
    },
    forceRenderFn(data){
      if (data.type) {
        this.hisCount = data.count
        if(this.hisCount == 0) {
          this.$refs.tab.setTabName(1,  `更早消息`)
        } else {
          this.$refs.tab.setTabName(1,  `更早消息（${this.hisCount}条未读）`)
        }
      } else {
        this.count = data
        if(this.count == 0) {
          this.$refs.tab.setTabName(0,  `最新消息`)
        } else {
          this.$refs.tab.setTabName(0,  `最新消息（${this.count}条未读）`)
        }
      }
    },
    // 得到未读的消息数量
    getNnreadList () {
      this.$ajax({
        url: '/api-uum/innermessage/unreadNumber/get',
        method: 'get',
        data: {
          sendDateStart: getDateForDay(6)
        }
      }).then((data) => {
        this.count = data.data.data.count
        this.show = true
        this.initTabs()
      })
    },
    // 得到最早未读消息
    getHistoryNnreadList () {
      this.$ajax({
        url: '/api-uum/history/innermessage/unreadNumber/get',
        method: 'get',
        data: {
          sendDateEnd: getDateForDay(6)
        }
      }).then((data) => {
        this.hisCount = data.data.data.count
        if(this.hisCount == 0) {
          this.$refs.tab.setTabName(1,  `更早消息`)
        } else {
          this.$refs.tab.setTabName(1,  `更早消息（${this.hisCount}条未读）`)
        }
        // this.initTabs()
      })
    },
    tabClick (name) {
      if (name === 'unreadMessage') {
        this.learnContent = 'unreadMessage'
      } else {
        this.learnContent = 'allMessage'
      }
    },
    initTabs (data) {
      this.navList = [{
        label:this.count !== 0 ? `最新消息（${this.count}条未读）` : `最新消息`,
        name: 'unreadMessage'
      },
      {
        label: this.hisCount !== 0 ? `更早消息（${this.hisCount}条未读）`: `更早消息`,
        name: 'allMessage'
      }
      ]
    }
  },
  components: {
    Tab,
    allMessage,
    unreadMessage
  }
}
</script>

<style lang="scss" scoped>
.iv-message {
  // height: 100%;
  background-color: #fff;
  /deep/ .iv-drag-table {
    .ivu-table-row {
      .ivu-table-cell {
        font-size: 16px;
        color: rgba($color: #000000, $alpha: 0.65);
      }
    }
  }
}
</style>
