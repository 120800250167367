import operate from './operate'
import {timeFormat} from '@/utils'

export default function () {
  return [
    // {
    //   title: '紧急程度',
    //   [$field]: 'urgency',
    //   showType: 'select',
    //   width: 100,
    //   searchKey: 'urgency',
    //   optionList: [{
    //     label: '一般',
    //     value: '0'
    //   }, {
    //     label: '紧急',
    //     value: '1'
    //   }],
    //   isEdit: true
    // },
    {
      title: '消息标题',
      [$field]: 'msgTitle',
      width: 300,
      searchKey: 'msgTitle',
      render (c, {row}) {
        return (
          <span>
            <span style="width: 27px; float: left; margin-left: 8px; line-height: 17px">
              {
                row.state === 0 ? <i class="iv-pull-left iv-fs-14 icon iconfont iv-middle icon-xiaoxi" style="color: #F5A623"></i> : <span>&nbsp;</span>
              }
              {
                row.state === 1 ? <i class="iv-pull-left iv-fs-14 icon iconfont iv-middle icon-envelope-open-full" style="color: #DDDDDD"></i> : <span>&nbsp;</span>
                // row.urgency === 1 ? <i class="iv-pull-right iv-fs-14 icon iconfont iv-middle icon-qizi1" style="color: #FA2900"></i> : <span>&nbsp;</span>
              }
            </span>
            <span class='iv-fs-14'>{row.msgTitle}</span>
          </span>
        )
      }
    },
    {
      title: '消息内容',
      [$field]: 'sendContent',
      searchKey: 'sendContent',
      render: (h, {row}) => {
        return (
          <span class={{'iv-pointer': row.state === 0, 'iv-fs-14' : true, 'iv-fc-65': true}} onClick={ e => { this.operateClick(row, 'read') } }>{row.sendContent}</span>
        )
      }
    },
    {
      title: '消息分类',
      [$field]: 'groupName',
      showType: 'select',
      width: 100,
      searchKey: 'groupName'
    },
    {
      title: '提醒时间',
      [$field]: 'sendDate',
      showType: 'datePicker',
      width: 150,
      props: {},
      config: {
        searchKeyMap: ['sendDateStart', 'sendDateEnd'],
        format: 'yyyy-MM-dd HH:mm:ss'
      },
      render (c, { row }) {
        let time = timeFormat(row.sendDate, 'yyyy-MM-dd hh:mm')
        return c('div', {
          class: 'iv-fc-45'
        }, [
          // c('i', {
          //   class: 'icon iconfont icon-shijian2 iv-fs-14 iv-m-r-4'
          // }),
          c('span', time)
        ])
      }
    },
    {
      title: '操作',
      width: '100px',
      isOperate: true,
      slotComponent: operate
    }
  ]
}

// function fnTime (time) {
//   let ptime = new Date(time).getTime()
//   const twentyFourHours = 24 * 60 * 60 * 1000
//   const fortyEightHours = 24 * 60 * 60 * 1000 * 2
//   const date = new Date()
//   const year = date.getFullYear()
//   const month = date.getMonth() + 1
//   const day = date.getDate()
//   const today = `${year}-${month}-${day}`
//   const todayTime = new Date(today).getTime()
//   const yesterdayTime = new Date(todayTime - twentyFourHours).getTime()
//   const lastYesterdayTime = new Date(todayTime - fortyEightHours).getTime()
//   if (ptime >= todayTime) {
//     return '今天 ' + time.split(' ')[1]
//   } else if (ptime < todayTime && yesterdayTime <= ptime) {
//     return '昨天 ' + time.split(' ')[1]
//   } else if (ptime < yesterdayTime && lastYesterdayTime <= ptime) {
//     return '前天 ' + time.split(' ')[1]
//   } else {
//     return time
//   }
// }
