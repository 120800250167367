<template>
  <div class="iv-message">
    <div class="iv-main-width iv-p-t-20">
      <SearchLayout :list="searchList" :spreadType="2" @on-search="onSearch"></SearchLayout>
      <p class="iv-heng"></p>
      <template v-if="show">
        <div class="iv-box">
          <Button style="margin-left: 8px" type="ghost" class="iv-cancel iv-weidu" noIcon
                  @click="setSomeRead"
          >批量已读
          </Button>
          <Button class="iv-tijiao iv-yidu" type="ghost" noIcon @click="setAllRead">全部设为已读</Button>
        </div>
        <TreeList
            class="iv-m-t-12 iv-fs-14"
            autoHeight
            noSearch
            ref="treeList"
            :columns="columns"
            :showTree="false"
            :showSelect="true"
            :showDelete="false"
            :showEdit="false"
            :showPage="true"
            :keepPage="true"
            :immediateForce="true"
            :listData="listData"
            :url="'api-uum/history/innermessage/my/list'"
            @on-selection-change="onSelectionChange">
        </TreeList>
      </template>
      <NoData v-else message="暂无消息"></NoData>
    </div>
  </div>
</template>

<script>
import columns from './columns'
import searchList from './searchList'
import NoData from '@/components/no-data'
import TreeList from '@/components/drag-table-inline'
import SearchLayout from '@/components/search-layout'
import {getToken} from "@/utils";
import {frameNameMap, jumpFromMsg} from "@/utils/todo";
// import { cloneDeep } from 'lodash'
export default {
  data() {
    return {
      operationList: [],
      show: true,
      columns: [],
      rules: [],
      selectionChange: [],
      innermessageIds: [],//存储批量消息ID
      searchParams: {},
      total: 0,
      AllIds: [],
      count: 0,
      listData: {}
    }
  },
  provide() {
    return {
      operateClick: this.operateClick
    }
  },
  async created() {
    this.columns = columns.call(this)
    this.searchList = searchList.call(this)
    this.getWeekDay()
  },
  methods: {
    getWeekDay() {//获取最近7天的事件
      // 获取当前日期
      const today = new Date();
      // 计算7天前的日期
      const sevenDaysAgo = new Date(today.getTime() - (6 * 24 * 60 * 60 * 1000));
      // 格式化日期输出，例如：2024-07-12
      const formattedDate = sevenDaysAgo.toISOString().split('T')[0];
      this.listData.sendDateEnd = formattedDate + " 00:00:00"
    },
    // 得到未读消息的数量
    getNnreadList() {
      this.$ajax({
        url: '/api-uum/history/innermessage/unreadNumber/get',
        method: 'get',
      }).then((data) => {
        let historyCountObj = {}
        let type = '0'
        this.count = data.data.data.count
        historyCountObj.count = this.count
        historyCountObj.type = type
        this.$emit('forceRender', historyCountObj)
        this.$methods('treeList', 'forceRender')
      })
    },
    // 设为全部已读
    getAllRead() {
      this.$ajax({
        url: '/api-uum/history/innermessage/allread/update',
        method: 'put',
        data: {}
      }).then((data) => {
        this.getNnreadList()
        // this.$methods('treeList', 'forceRender')
      })
    },
    // 设置为已读/批量设置已读
    getReadInnermessage(ids) {
      this.$ajax({
        url: '/api-uum/history/innermessage/read/update',
        method: 'put',
        data: {
          innerMessageIds: ids
        }
      }).then((data) => {
        this.getNnreadList()
        this.$methods('treeList', 'forceRender')
        this.innermessageIds = []
      })
    },
    //处理跳转对应路由
    JumpDisposeMsg(item) {
      setTimeout(() => {
        this.getNnreadList()
      }, 1000)
      const {innerMessageId, systemCode, gotoUrl, businessId, systemUrl, msgObjectCode} = item
      //  portal和学习走老的跳转逻辑
      const learningMsgObjectCodeList = [
        "TX-ZXXX01-1",
        "TX-ZXXX01-2",
        "TX-ZXXX01-3",
        "TX-ZXXX01-4",
        "TX-ZXXX01-5",
        "TX-ZXXX01-6",
        "TX-ZXXX01-7",
        "TX-ZXXX01-8",
        "TX-ZXXX01-9",
        "TX-ZXXX01-10"
      ]
      if (systemUrl === 'portal' || learningMsgObjectCodeList.includes(msgObjectCode)) {
        let url = `/api-uum/history/innermessage/disposeMsg/get?innerMsgId=${innerMessageId}&access_token=${sessionStorage.getItem('token')}`
        window.open(url)
        return
      }
      // YWX01 代表的是智慧党建 jiban 接诉即办
      if ((frameNameMap[systemCode] === 'djt' || systemCode === 'jiban' || systemCode === 'YWX01')) {
        jumpFromMsg(innerMessageId, sessionStorage.getItem('token'), systemCode, gotoUrl, businessId, 'open')
      }
    },
    // 全部设置为已读
    setAllRead() {
      this.getAllRead()
    },
    //设置部分已读
    setSomeRead() {
      if (this.innermessageIds.length == 0) {
        this.$Message.error('请先选择')
      } else {
        this.getReadInnermessage(this.innermessageIds)
      }
    },
    //搜索
    onSearch(params, type) {
      this.listData = Object.assign(this.listData, params)
      this.$methods('treeList', 'forceRender')
    },
    // 选择消息时
    onSelectionChange(list) {
      this.innermessageIds = list.map(item => {
        return item.innerMessageId
      })
      this.selectionChange = list
    },
    operateClick(item, type) {
      switch (type) {
        case '处理':
          this.getReadInnermessage(item.innerMessageId)
          this.JumpDisposeMsg(item)
          break
        case '设为已读':
          this.getReadInnermessage(item.innerMessageId)
          break
        case '查看详情':
          this.getReadInnermessage(item.innerMessageId)
          this.jumpNewsDetail(item)
          break
        default:
          this.setReadMsg(item, type)
          break
      }
    },
    jumpNewsDetail(item) {
      const href = `${sessionStorage.getItem('jumpCmsUrl')}/#${item.gotoUrl}&access_token=${getToken()}`
      window.open(href, '_blank')
      setTimeout(() => {
        this.getNnreadList()
        this.$methods('treeList', 'forceRender')
      }, 1000)
    }
  },
  components: {
    NoData,
    TreeList,
    SearchLayout
  }
}
</script>

<style lang="scss" scoped>
.iv-message {
  height: 100%;;
  background-color: #fff;

  /deep/ .iv-drag-table {
    .ivu-table-row {
      .ivu-table-cell {
        font-size: 14px !important;
        color: rgba($color: #000000, $alpha: 0.65);
      }
    }
  }
}

.iv-toubu {
  display: flex;
  width: 1200px;
  flex-wrap: wrap;
  // justify-content: space-around;
}

.iv-form {
  width: 400px;
  display: inline-block;
  justify-content: space-between;
}

.iv-form-kuang, .ivu-date-picker {
  width: 302px !important;
  height: 40px;
  line-height: 40px;
  border-radius: 8px;
}

/deep/ .ivu-input {
  height: 40px !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

/deep/ .ivu-select-placeholder {
  height: 40px !important;
  line-height: 40px !important;
}

/deep/ .ivu-select-selection {
  height: 40px !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
  line-height: 40px;
}

/deep/ .ivu-select-arrow {
  margin-top: -5px;
}

/deep/ .ivu-form-item-label {
  color: rgba($color: #000000, $alpha: 0.65);
  font-size: 14px;
}

.iv-botton {
  float: right;
  // margin-top: 10px;
  width: 400px;
}

.iv-tijiao {
  border-radius: 8px;
  width: 88px;
  height: 40px;
  margin-left: 116px;
}

.iv-cancel {
  border-radius: 8px;
  width: 88px;
  height: 40px;
}

.iv-heng {
  height: 1px;
  background: rgba(0, 0, 0, 0.15);
  // border: 1px solid rgba(0, 0, 0, 0.15);
  margin: 20px 0;
}

.iv-box {
  width: 1200px;
  height: 40px;

  .iv-yidu {
    float: right;
    width: 128px;
  }

  .iv-weidu {
    float: right;
    width: 96px;
  }
}
</style>
