export default function () {
  return [{
    label: '子系统',
    type: 'Select',
    name: 'systemCode',
    // optionList: this.systemList
    optionList: [
      {
        value: 'YWX01',
        label: '党建'
      },
      {
        value: 'YWX03',
        label: '工会'
      },
      {
        value: 'YWX04',
        label: '青团'
      },
      {
        value: 'YWX05',
        label: '文化'
      },
      {
        value: 'jiban',
        label: '接诉即办'
      },
    ]
  },
  {
    label: '消息分类',
    type: 'Input',
    name: 'groupName',
  },
  {
    label: '消息标题',
    type: 'Input',
    name: 'msgTitle'
  },
  {
    label: '消息内容',
    type: 'Input',
    name: 'sendContent'
  },
  {
    label: '提醒时间',
    type: 'DatePicker',
    name: 'sendDate',
    props: {
      format: 'yyyy-MM-dd'
    },
    config: {
      searchKeyMap: ['sendDateStart', 'sendDateEnd']
    }
  }
  ]
}